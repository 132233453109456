body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#spinner {
  position: fixed;
  width: 100%;
  height:100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  top: 0;
}

.spin {
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;

}

.page {
  margin-top: 2rem;
}

h2{
  margin-bottom: 1.5rem;
}

.fine-print {
  font-size: 10pt;
}

html, body {
  margin-bottom: 20px;
}

.wrap {
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.applePayContainer {
  min-height: 40px;
  height: 40px;
}

.paymentMethodButton {
  min-height: 5rem;
  max-height: 100%;
  width: 100%;
  border: 1px solid black;
}

.logo {
  text-align: left;
}

.footer {
  background: white;
  padding-top: 1rem;
}

@media only screen and (min-width: 720px) {
  .vb-header {
    height: 4rem;
  }
}

@media only screen and (max-width: 720px) {
  .vb-header {
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

.vb-order-detail-table td {
  min-width: 50%;
  width: 50%;
  max-width: 50%;
}

.vb-jumbotron {
  padding-top: 25px;
  padding-bottom: 25px;
}

.vb-nav {
  text-align: center;
}

.logout {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.order-image {
  padding: 10px;
}

.order-image img {
  margin: 0 auto;
  width: 100%;
  max-width: 30rem;
}

@media only screen and (max-width: 720px) {
  .vb-mobile-center {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media only screen and (min-width: 720px) {
  .vb-nav-link {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.order-detail-row {
  border-bottom: 1px solid #dee2e6;
}

.order-detail-column {
  padding: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin: 0;
}

.app-option-label {
  margin-left: 2rem;
}

.article-list-row {
  margin-top: 1rem;
}

.article-list-title {
  color: black;
}

.article-list-title:hover {
  text-decoration: none;
  color: #6c757d
}

.newsIframe {
  border: 0;
  width: 100%;
  height: 0;
}

.padding-top-2 {
  padding-top: 3rem;
}

.section {
  padding-top: 1rem;
}

.cart-header-container {
  top: 4.6rem;
  width: 100%;
  height: 50px;
}

.cart-header {
  background: #e9ecef;
  padding: 1rem 0;
  width: 100%;
  overflow: hidden;
}

.cart-header-items {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cart-header-left-items {
  display: -webkit-flex;
  display: flex;
}

.cart-header-right-items {
  display: -webkit-flex;
  display: flex;
}

.right-justify {
  text-align: right;
}

.cart-header-left-items .image {
  padding-right: 2rem;
  -webkit-align-self: center;
          align-self: center;
}

.cart-header-left-items .image img {
  height: 2rem;
}
.cart-header-left-items .title {
  -webkit-align-self: center;
          align-self: center;
  overflow: hidden;
}

.cart-header-right-items .total {
  -webkit-align-self: center;
          align-self: center;
}

.checkout-page {
  margin-top: -32px;
}
